<template>
  <AuthFormContainer title="Contact Us">
    <div class="contact-form">

      <div>
        <CNInput
          v-model="formData.first_name"
          :invalid="!!validationErrors.first_name"
          @blur="handleChangeField('first_name', $event)"
          maxlength="100"
          label="Enter First name"
          :required="false" />

        <CFormText v-if="validationErrors.first_name" :style="`color: ${validationErrors.first_name && 'red'}`">
          {{ validationErrors.first_name }}
        </CFormText>
      </div>

      <div>
        <CNInput
          v-model="formData.last_name"
          :invalid="!!validationErrors.last_name"
          @blur="handleChangeField('last_name', $event)"
          maxlength="100"
          label="Enter Last name"
          :required="false" />

        <CFormText v-if="validationErrors.last_name" :style="`color: ${validationErrors.last_name && 'red'}`">
          {{ validationErrors.last_name }}
        </CFormText>
      </div>

      <div>
        <CNInput
          v-model="formData.email"
          :invalid="!!validationErrors.email"
          @blur="handleChangeField('email', $event)"
          maxlength="100"
          label="Enter Email"
          :required="false" />

        <CFormText v-if="validationErrors.email" :style="`color: ${validationErrors.email && 'red'}`">
          {{ validationErrors.email }}
        </CFormText>
      </div>

      <div>
        <CNInput
          v-model="formData.company_name"
          :invalid="!!validationErrors.company_name"
          @blur="handleChangeField('company_name', $event)"
          maxlength="100"
          label="Enter Company name"
          :required="false" />

        <CFormText v-if="validationErrors.company_name" :style="`color: ${validationErrors.company_name && 'red'}`">
          {{ validationErrors.company_name }}
        </CFormText>
      </div>

      <div>
        <CNInput
          v-model="formData.phone_number"
          :mask="CONF.MASK_FORMAT_PHONE"
          :invalid="!!validationErrors.phone_number"
          @blur="handleChangeField('phone_number', $event)"
          max-length="100"
          label="Enter Phone Number"
          :required="false" />

        <CFormText v-if="validationErrors.phone_number" :style="`color: ${validationErrors.phone_number && 'red'}`">
          {{ validationErrors.phone_number }}
        </CFormText>
      </div>

      <div class="contact-form__footer">
        <router-link to="/login">
          <CButton color="primary" variant="outline">
            Go to login page
          </CButton>
        </router-link>
        <CButton :disabled="submitLoading" color="primary" @click="submit">
          Contact us
        </CButton>
      </div>


    </div>
  </AuthFormContainer>
</template>

<script>
import AuthFormContainer from "@/components/AuthFormContainer.vue";
import validator from "@/utils/validator";
import rules from "@/utils/validator/rules";
import {parseError} from "@/utils/api";
import CNInput from "@/components/ui/CNInput/CNInput.vue";
import * as CONF from "@/utils/constants/config";

export default {
  name: "ContactUs",
  components: {CNInput, AuthFormContainer},
  inject: ['toast'],
  data() {
    return {
      formData: {
        first_name: null,
        last_name: null,
        phone_number: null,
        email: null,
        company_name: null
      },

      submitLoading: false,

      validator: {},
      validationErrors: {}
    }
  },
  computed: {
    CONF() {
      return CONF
    },
  },
  methods: {
    submit() {
      const validationResult = this.validator.validateAll(this.formData);

      if (!validationResult.hasErrors) {
        this.submitLoading = true

        this.$http.auth.contactUS(this.formData)
          .then(res => {
            this.toast('success', res.data.message)
            this.$router.push('/login');
          })
          .catch(err => {
            err = parseError(err)
            if (err.status === 422) {
              this.validationErrors = err.validationMessages
            } else {
              this.toast('error', err.message)
            }
          })
          .finally(() => {
            this.submitLoading = false
          })
      } else {
        this.validationErrors = validationResult.validationErrors
      }

    },
    handleChangeField(field, value) {
      this.validationErrors[field] = this.validator.validate(
        field,
        value,
      )
    },
    // rules.digitsPhone, rules.strMinPhone(14, 10)
    setRules() {
      this.validator = validator({
        first_name: [],
        last_name: [],
        phone_number: [],
        email: [rules.required, rules.email],
        company_name: []
      })
    },
  },
  created() {
    this.setRules();
  }
}
</script>

<style scoped lang="scss">
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 36px;

  &__footer {
    display: flex;
    gap: 16px;
  }
}
</style>
